import './App.css';
import PTRFooter from './Components/PTRFooter';
import PTRHeader from './Components/PTRHeader';
import PandaDocHolder from './Pages/PandaDocHolder';
import { useEffect, useState } from 'react';

function App() {
    const [quoteId, setQuoteId] = useState(false);

    useEffect(() => {
        getUrlParams();
    });

    const getUrlParams = () => {
        const queryParameters = new URLSearchParams(window.location.search);
        const quoteIdParam = queryParameters.get("QuoteID");
        setQuoteId(quoteIdParam);
    }

    return (
        <div className="App">
            <PTRHeader />
            <PandaDocHolder quoteId={quoteId} />
            <PTRFooter />
        </div>
    );
}

export default App;
