import React from 'react';

function PTRFooter() {
  return (
      <footer className="footer mt-auto py-3 bg-light">
          <div className="container">
              <span className="text-muted">&#169; <span>{(new Date().getFullYear())}</span> PTR All rights reserved</span>
          </div>
      </footer>
  );
}

export default PTRFooter;