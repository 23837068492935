import React, { useEffect } from 'react';

function PandaDocIFrame({ itemLines, recipients, tokens, callToAttachDocuments, unitNumber }) {

    useEffect(() => {
        if (itemLines !== null) {
            createPandaDoc();
        }
    }, [itemLines]);

    const createPandaDoc = () => {
        // eslint-disable-next-line
        let editor = new PandaDoc.DocEditor();
        editor.show({
            el: '#pandadoc-editor',
            data: {
                docName: tokens.docName,
                recipients: recipients,
                tokens: tokens,
                items: itemLines
            },
            events: {
                onDocumentCreated: function (data) {
                    callToAttachDocuments(unitNumber, data.document.id);
                }
            }
        });
    }

    return (
        <div id="pandadoc-editor" className="pandadoc"></div>
    );
}

export default PandaDocIFrame;