import React from 'react';

function PTRHeader() {
    return (
        <div className="container">
            <header className="d-flex flex-wrap justify-content-center py-3 mb-4 border-bottom">
                <div className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none">

                    <img className="bi me-2" src="/logo.png" />
                    <span className="fs-4">Rental Panda Docs</span>
                </div>
            </header>
        </div>
    );
}

export default PTRHeader;